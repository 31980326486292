<template>
  <div class="flex flex-col gap-6 p-5">
    <soludio-input class="w-2/3 mt-5"
                    type="search" v-model="externalId" @change="changeItem" @input="searchItem" :options="items"
                    option-value="id"
                    option-label="sku" label="Find Correct Item" placeholder="Please Enter Original Item"></soludio-input>
    <soludio-input disabled v-model="itemData.type" type="select" :options="itemTypes" option-value="value" option-label="label" class="w-2/3" label="Item Type" placeholder="Please Enter Item Type"></soludio-input>
    <template v-if="itemData.type === 'alias'">
      <soludio-input v-for="(item, key) in itemData.item_parts" class="w-2/3" type="search" v-model="item.item_part_id" @input="searchItem" :options="items"
                     option-value="id"
                     disabled
                     :key="key"
                     option-label="sku" label="Original Item" placeholder="Please Enter Original Item"></soludio-input>
    </template>
    <soludio-input disabled class="w-2/3" v-model="itemData.sku" label="Sku Number" placeholder="Please Enter Sku Number"></soludio-input>
    <div class="flex flex-col gap-4" v-if="itemData.type === 'assembly'">
      <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
        <img height="24"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACd0lEQVR4nO1aS07DMBScVbMCBOzopZBQOUShPWQrPl1AWHYNJ6Dp3ijoRYoqGvsl89w4ZCSvOrKfJ7Yz4wYY8YsnAM64rdHjelykForo9ThlgVq0FSBaPW4UAOMKcDGXHJnfuR43rgBEUbxsewCvAB4BTBT8BYCMXE+0FbA+8hrKAUwJ/N4LUMcZgFsAWxnzvWEl/MXPCSvhpAJUuATwKeM+QMcvt0/yApSYybjP0PFfMBABzmXcbyV/NxQBLpQCXAxNgHvlFrgf0ha4BvAl486V/KQPwXN5ktVk3jyvwUN+7uGbCZABWIor2ysd2rELjnLyNwr+yYzQFMBHw8WCr7A6dycTnAda4ULOiCbrbCpAVpt8aUTuxJ1pHFrSaXBZm/xVS4eWtAAb+a188m0dWtICFPJbueTbOrSkBXCBxTD6aMunCeAaWminXfoI7WtN+m/BewFRtRX8YPQR2hfjgWjq6RUYWzJpFAGHMis19hLVa7k0YdapkZYFmFjI5LZiwixTIy0LMJHJeJU7nYkPsUiNtCzAxrQmgvkDWRKygAUmMt6LHIwWqZGWBZJGQcgCScP9B+PxFMF6Mr8RYmQB1TdCjCzADEOO3YczXr5tBejSl6oPl5AAjCyQtAAbQhZIWoAFIQskLUBGyAKmAmQHqdF3cmv5jCxgJsC0ITWuCHxWFjARIDuSGll8JkwEWHpSY1d+7wXYBKTGLvzeC1AEGJQufCZGAWC4BW6N+L0XYOFxaF35TJi9BvM/HBqLz4SpEcoVlxhaPgumVnhy4NB81lbLZyDpMMTAKADGFYBxCzTuif96Bjjjpi0wWj3rCIOxvxGKWQ8Gix/CdpAixnnzhwAAAABJRU5ErkJggg=="/>
        Bill Of Materials (Sub-assemblies)</h3>

      <div v-for="(item, key) in itemData.item_parts" class="w-2/3 flex gap-2 items-end">
        <soludio-input disabled  class="w-2/3" type="search" v-model="item.item_part_id" :options="items"
                        option-value="id"
                        option-label="sku" label="Item" placeholder="Please Select Bill Of Material"></soludio-input>
        <soludio-input disabled class="w-1/3" v-model.number="item.quantity" type="number"
                       label="Quantity" placeholder="Please Enter Quantity"></soludio-input>

      </div>

    </div>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMyA2LjA2NnYxMi4wNjVsLTExLjAwMSA1Ljg2OS0xMS01Ljg2OXYtMTIuMTMxbDExLTYgMTEuMDAxIDYuMDY2em0tMjEuMDAxIDExLjQ2NWw5LjUgNS4wNjl2LTEwLjU3bC05LjUtNC45NDZ2MTAuNDQ3em0yMC4wMDEtMTAuMzg4bC05LjUwMSA0Ljg4OXYxMC41NjhsOS41MDEtNS4wNjl2LTEwLjM4OHptLTUuNTIgMS43MTZsLTkuNTM0LTQuOTY0LTQuMzQ5IDIuMzczIDkuNDA0IDQuODk2IDQuNDc5LTIuMzA1em0tOC40NzYtNS41NDFsOS41NjUgNC45OCAzLjgzMi0xLjk3Mi05LjQwNS01LjE4NS0zLjk5MiAyLjE3N3oiLz48L3N2Zz4="/>
          Item Information</h3>
        <soludio-input disabled v-model="itemData.upc" class="w-2/3" label="UPC (optional)" placeholder="Please Enter UPC"></soludio-input>
        <soludio-input disabled v-model="itemData.description" class="w-2/3" label="Description (optional)" placeholder="Please Enter Description" ></soludio-input>
        <soludio-input disabled v-model="itemData.external_sku_ref_list" class="w-2/3" type="text-area"
                       label="External SKU Ref List (optional)" placeholder="Please Enter Description" ></soludio-input>
      </div>
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 font-semibold text-gray-700 flex items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYyIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOCA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0zIDE5YzAgMS4zMDItLjgzOSAyLjQwMS0yIDIuODE2di0yLjgxNmgtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2M2gtMnYtM2gtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2Mi44MTVjLTEuMTYxLS40MTQtMi0xLjUxMy0yLTIuODE1di0xNGMwLTEuMzAyLjgzOS0yLjQwMSAyLTIuODE2djIuODE2aDF2LTNoMnY2aDF2LTZoMnYzaDF2LTNoMnYzaDF2LTNoMnY2aDF2LTZoMnYzaDF2LTIuODE2YzEuMTYxLjQxNSAyIDEuNTE0IDIgMi44MTZ2MTR6bS0xNC4xNDEtOWgxLjE0MXY2aC0xLjM1NHYtMy44ODloLTEuNDg4di0xYy44OTMtLjA0MSAxLjcwMS0uMjAxIDEuNzAxLTEuMTExem04LjMxNSA0LjY2N2gxLjgyNnYxLjMwNmgtMy45MjJ2LS45NThjMS41MjktMS40OTEgMi4zNzktMi4yNDQgMi4zODktMy4wMTggMC0uNDYyLS4yNjgtLjcxNy0uNzU0LS43MTctLjQ1MyAwLS44MTYuMjQ1LTEuMTY4LjUyNGwtLjQyNC0xLjE0OGMuNTQ1LS40NTIgMS4xOTEtLjY1NiAxLjgzMy0uNjU2IDEuMTc5IDAgMS45NDEuNzQgMS45NDEgMS44ODUuMDAxIDEuMTU1LS44OTkgMi4wNDMtMS43MjEgMi43ODJ6Ii8+PC9zdmc+"/>
          Shipment Details</h3>
        <soludio-input disabled v-if="itemData.type === 'assembly'" v-model="itemData.assembly_packaging_type" type="select" :options="assemblyPackagingTypes" option-value="value" option-label="label" class="w-2/3" label="Assembly Item Shipment Details" placeholder="Please Select the Assembly Item Shipment Details"></soludio-input>

        <template v-if="itemData.type !== 'assembly' || (itemData.type === 'assembly' && itemData.assembly_packaging_type === 'consolidated')">
        <div class="flex gap-2 w-2/3">
          <soludio-input disabled v-model="itemData.length" class="w-1/3" type="number"
                         label="Length(In.)" placeholder="Please Enter Length"></soludio-input>
          <soludio-input disabled v-model="itemData.width" class="w-1/3" type="number" label="Width(In.)"
                         placeholder="Please Enter Width"></soludio-input>
          <soludio-input disabled v-model="itemData.height" class="w-1/3" type="number" label="Height(In.)"
                         placeholder="Please Enter Height"></soludio-input>
        </div>
          <soludio-input disabled v-model="itemData.weight" class="w-2/3" type="number" label="Weight(Lb.)"
                         placeholder="Please Enter Weight"></soludio-input>
          <soludio-input disabled v-model="itemData.pack_group.id"
                         @input="searchPackGroups"
                         option-value="id"
                         option-label="name" :options="packGroups" type="search" class="w-2/3" label="Packgroup"
                         placeholder="Please Enter Packgroup"></soludio-input>
          <soludio-input disabled v-model="itemData.print_folder" class="w-2/3" label="Print Folder"
                         placeholder="Please Enter Print Folder"></soludio-input>
          <soludio-input disabled v-model="itemData.shipment_type" type="select" :options="shipmentTypes" option-value="value" option-label="label" class="w-2/3" label="Shipment Type" placeholder="Please Enter Shipment Type"></soludio-input>
          <soludio-input disabled v-model="itemData.sku_on_label" type="select" :options="skuOnLabels" option-value="value" option-label="label" class="w-2/3" label="SKU On Label" placeholder="Please Enter SKU On Label"></soludio-input>
          <soludio-input disabled v-model="itemData.shipping_label_sort_order_prefix" class="w-2/3" label="Shipping Label Sort Order Prefix"
                         placeholder="Please Enter Shipping Label Sort Order Prefix"></soludio-input>
        </template>
      </div>
    </div>
    <div class="flex">
      <pg-button @click="save" color="green" class="w-32">Save</pg-button>
    </div>
  </div>
</template>

<script>
import Methods from "@/views/items/item/forms/methods";
import Data from "@/views/items/item/forms/data";

export default {
  name: "ExternalCreateItem",
  mixins: [Data, Methods],
  props: {
    close: {
      type: Function,
      default: ()=> {}
    },
    externalSku: String,
  },
  data(){
    return {
      externalId: null,
      itemData: {
        id:null,
        type: 'single',
        sku: null,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        assembly_packaging_type: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        pack_group: {
          id: null
        },
        item_parts: [
          {
            item_part_id: null,
            quantity: 1
          }
        ],
        sku_on_label: "SELF",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },

    }
  },
  computed: {
    switchStyle() {
      return {
        backgroundColor: this.isExternal ? "#64BFE5" : "#e1e1e1",
        color: "#fff",
      };
    }
    },
  methods: {
    getItemData(id) {
      if(!id) return
      this.$spinner.fingerprint();
      this.$store
        .dispatch("items/fetchSelectData", id)
        .then((response) => {
          this.setItemFormData(response.data)
          this.$spinner.close();
        })
        .catch((error) => {
          console.log(error)
          this.$spinner.close();
          this.$swal({
            icon: "error",
            title: "Item Not Found",
          });
        });
    },
    changeItem(id){
      if(!id) return
      this.getItemData(id)
    },
    setItemFormData(data){
      if(!data) return
      Object.entries(this.itemData).forEach(([key, value])=> {
        if(!["item_parts"].includes(key)){
          this.itemData[key] = data[key]
        }
        if(key === "item_parts" && data.item_parts.length > 0){
          this.itemData.item_parts = data.item_parts
          this.searchItem([...data.item_parts.map(v => v.item_part_id), this.externalId].join(","))
        }
        this.searchPackGroups(data.pack_group?.id)

        let splitExternals = data.external_sku_ref_list?.split(",") || []
        splitExternals = splitExternals.filter(v => v)
        splitExternals.push(this.externalSku)
        this.itemData.external_sku_ref_list = splitExternals.join(",")
      })
    },
    save(){
      let {item_parts, pack_group, ...data} = this.itemData
      if(data.assembly_packaging_type === "individual"){
        data.shipping_label_sort_order_prefix = ""
        data.length = 0
        data.weight = 0
        data.width = 0
        data.height = 0
        data.print_folder = ""
        data.shipment_type = ""
        data.sku_on_label = "BASE"
      }
      data["pack_group_id"] = pack_group.id
      data["item_parts"] = item_parts.filter(v => v.item_part_id && v.quantity)

      this.$store
        .dispatch("items/updateItem", data)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Item Updated Successfully",
            text: "Successfully Updated",
          }).then(()=>{
            this.close()
          })
        })
        .catch((error) => {
          if (error.status && error.status === 205) {
            error = "Item Already Exist";
          }
          this.$swal({
            icon: "error",
            title: "Failed to update product",
            text: error || "An error occured",
          });
        });
    }
  },
}
</script>

<style scoped>

</style>
