<template>
  <div class="flex flex-col gap-6 p-5">
    <div class="w-full justify-end flex">
      <pg-button @click="showItemDetailModal" color="#28A744">Edit {{itemData.sku}}</pg-button>
    </div>
    <item-page v-if="showItemDetail" :show.sync="showItemDetail" :item-id="itemId" process-type="edit"></item-page>

    <template v-if="itemData.type === 'alias'">
      <soludio-input v-for="(item, key) in itemData.item_parts"  type="search" v-model="item.item_part_id" @input="searchItem" :options="items"
                     option-value="id"
                     disabled
                     :key="key"
                     option-label="sku" label="Original Item" placeholder="Please Enter Original Item"></soludio-input>
    </template>
    <soludio-input disabled  v-model="itemData.sku" label="Sku Number" placeholder="Please Enter Sku Number"></soludio-input>
    <div class="flex flex-col gap-4" v-if="itemData.type === 'assembly'">
      <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
        <img height="24"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACd0lEQVR4nO1aS07DMBScVbMCBOzopZBQOUShPWQrPl1AWHYNJ6Dp3ijoRYoqGvsl89w4ZCSvOrKfJ7Yz4wYY8YsnAM64rdHjelykForo9ThlgVq0FSBaPW4UAOMKcDGXHJnfuR43rgBEUbxsewCvAB4BTBT8BYCMXE+0FbA+8hrKAUwJ/N4LUMcZgFsAWxnzvWEl/MXPCSvhpAJUuATwKeM+QMcvt0/yApSYybjP0PFfMBABzmXcbyV/NxQBLpQCXAxNgHvlFrgf0ha4BvAl486V/KQPwXN5ktVk3jyvwUN+7uGbCZABWIor2ysd2rELjnLyNwr+yYzQFMBHw8WCr7A6dycTnAda4ULOiCbrbCpAVpt8aUTuxJ1pHFrSaXBZm/xVS4eWtAAb+a188m0dWtICFPJbueTbOrSkBXCBxTD6aMunCeAaWminXfoI7WtN+m/BewFRtRX8YPQR2hfjgWjq6RUYWzJpFAGHMis19hLVa7k0YdapkZYFmFjI5LZiwixTIy0LMJHJeJU7nYkPsUiNtCzAxrQmgvkDWRKygAUmMt6LHIwWqZGWBZJGQcgCScP9B+PxFMF6Mr8RYmQB1TdCjCzADEOO3YczXr5tBejSl6oPl5AAjCyQtAAbQhZIWoAFIQskLUBGyAKmAmQHqdF3cmv5jCxgJsC0ITWuCHxWFjARIDuSGll8JkwEWHpSY1d+7wXYBKTGLvzeC1AEGJQufCZGAWC4BW6N+L0XYOFxaF35TJi9BvM/HBqLz4SpEcoVlxhaPgumVnhy4NB81lbLZyDpMMTAKADGFYBxCzTuif96Bjjjpi0wWj3rCIOxvxGKWQ8Gix/CdpAixnnzhwAAAABJRU5ErkJggg=="/>
        Bill Of Materials (Sub-assemblies)</h3>

      <div v-for="(item, key) in itemData.item_parts" class="flex gap-2 items-end">
        <soludio-input disabled   type="search" v-model="item.item_part_id" :options="items"
                       option-value="id"
                       option-label="sku" label="Item" placeholder="Please Select Bill Of Material"></soludio-input>
        <soludio-input disabled class="w-1/3" v-model.number="item.quantity" type="number"
                       label="Quantity" placeholder="Please Enter Quantity"></soludio-input>

      </div>

    </div>
    <div class="flex flex-col gap-5">
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMyA2LjA2NnYxMi4wNjVsLTExLjAwMSA1Ljg2OS0xMS01Ljg2OXYtMTIuMTMxbDExLTYgMTEuMDAxIDYuMDY2em0tMjEuMDAxIDExLjQ2NWw5LjUgNS4wNjl2LTEwLjU3bC05LjUtNC45NDZ2MTAuNDQ3em0yMC4wMDEtMTAuMzg4bC05LjUwMSA0Ljg4OXYxMC41NjhsOS41MDEtNS4wNjl2LTEwLjM4OHptLTUuNTIgMS43MTZsLTkuNTM0LTQuOTY0LTQuMzQ5IDIuMzczIDkuNDA0IDQuODk2IDQuNDc5LTIuMzA1em0tOC40NzYtNS41NDFsOS41NjUgNC45OCAzLjgzMi0xLjk3Mi05LjQwNS01LjE4NS0zLjk5MiAyLjE3N3oiLz48L3N2Zz4="/>
          Item Information</h3>
        <soludio-input disabled v-model="itemData.upc"  label="UPC (optional)" placeholder="Please Enter UPC"></soludio-input>
        <soludio-input disabled v-model="itemData.description"  label="Description (optional)" placeholder="Please Enter Description" ></soludio-input>
        <soludio-input disabled v-model="itemData.external_sku_ref_list"  type="text-area"
                       label="External SKU Ref List (optional)" placeholder="Please Enter Description" ></soludio-input>
      </div>
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 font-semibold text-gray-700 flex items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYyIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOCA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0zIDE5YzAgMS4zMDItLjgzOSAyLjQwMS0yIDIuODE2di0yLjgxNmgtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2M2gtMnYtM2gtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2Mi44MTVjLTEuMTYxLS40MTQtMi0xLjUxMy0yLTIuODE1di0xNGMwLTEuMzAyLjgzOS0yLjQwMSAyLTIuODE2djIuODE2aDF2LTNoMnY2aDF2LTZoMnYzaDF2LTNoMnYzaDF2LTNoMnY2aDF2LTZoMnYzaDF2LTIuODE2YzEuMTYxLjQxNSAyIDEuNTE0IDIgMi44MTZ2MTR6bS0xNC4xNDEtOWgxLjE0MXY2aC0xLjM1NHYtMy44ODloLTEuNDg4di0xYy44OTMtLjA0MSAxLjcwMS0uMjAxIDEuNzAxLTEuMTExem04LjMxNSA0LjY2N2gxLjgyNnYxLjMwNmgtMy45MjJ2LS45NThjMS41MjktMS40OTEgMi4zNzktMi4yNDQgMi4zODktMy4wMTggMC0uNDYyLS4yNjgtLjcxNy0uNzU0LS43MTctLjQ1MyAwLS44MTYuMjQ1LTEuMTY4LjUyNGwtLjQyNC0xLjE0OGMuNTQ1LS40NTIgMS4xOTEtLjY1NiAxLjgzMy0uNjU2IDEuMTc5IDAgMS45NDEuNzQgMS45NDEgMS44ODUuMDAxIDEuMTU1LS44OTkgMi4wNDMtMS43MjEgMi43ODJ6Ii8+PC9zdmc+"/>
          Shipment Details</h3>
        <soludio-input disabled v-if="itemData.type === 'assembly'" v-model="itemData.assembly_packaging_type" type="select" :options="assemblyPackagingTypes" option-value="value" option-label="label"  label="Assembly Item Shipment Details" placeholder="Please Select the Assembly Item Shipment Details"></soludio-input>

        <template v-if="itemData.type !== 'assembly' || (itemData.type === 'assembly' && itemData.assembly_packaging_type === 'consolidated')">
          <div class="flex gap-2 ">
            <soludio-input disabled v-model="itemData.length" class="w-1/3" type="number"
                           label="Length(In.)" placeholder="Please Enter Length"></soludio-input>
            <soludio-input disabled v-model="itemData.width" class="w-1/3" type="number" label="Width(In.)"
                           placeholder="Please Enter Width"></soludio-input>
            <soludio-input disabled v-model="itemData.height" class="w-1/3" type="number" label="Height(In.)"
                           placeholder="Please Enter Height"></soludio-input>
          </div>
          <soludio-input disabled v-model="itemData.weight"  type="number" label="Weight(Lb.)"
                         placeholder="Please Enter Weight"></soludio-input>
          <soludio-input disabled v-model="itemData.pack_group.id"
                         @input="searchPackGroups"
                         option-value="id"
                         option-label="name" :options="packGroups" type="search"  label="Packgroup"
                         placeholder="Please Enter Packgroup"></soludio-input>
          <soludio-input disabled v-model="itemData.print_folder"  label="Print Folder"
                         placeholder="Please Enter Print Folder"></soludio-input>
          <soludio-input disabled v-model="itemData.shipment_type" type="select" :options="shipmentTypes" option-value="value" option-label="label"  label="Shipment Type" placeholder="Please Enter Shipment Type"></soludio-input>
          <soludio-input disabled v-model="itemData.sku_on_label" type="select" :options="skuOnLabels" option-value="value" option-label="label"  label="SKU On Label" placeholder="Please Enter SKU On Label"></soludio-input>
          <soludio-input disabled v-model="itemData.shipping_label_sort_order_prefix"  label="Shipping Label Sort Order Prefix"
                         placeholder="Please Enter Shipping Label Sort Order Prefix"></soludio-input>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import ItemPage from "@/views/items/item/Index.vue";
export default {
  name: "ItemDetail",
  props: {
    itemId: [Number, String],
  },
  components: {
    ItemPage
  },
  data(){
    return {
      packGroups: [],
      items: [],
      shipmentTypes: [
        { value: "parcel", label: "Parcel" },
        { value: "freight", label: "Freight" },
      ],
      skuOnLabels: [
        { value: "SELF", label: "Show the SKU of this Item" },
        { value: "BASE", label: "Show the SKU of Item's Parts" },
      ],
      itemTypes: [
        { value: "single", label: "Single" },
        { value: "alias", label: "Alias" },
        { value: "assembly", label: "Assembly" },
      ],
      assemblyPackagingTypes: [
        { value: "individual", label: "Use the Shipment Details of the Sub-assemblies" },
        {
          value: "consolidated",
          label: "Enter the Shipment Details for This Item",
        },
      ],
      showItemDetail: false,
      itemData: {
        id:null,
        type: 'single',
        sku: null,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        assembly_packaging_type: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        pack_group: {
          id: null
        },
        item_parts: [
          {
            item_part_id: null,
            quantity: 1
          }
        ],
        sku_on_label: "SELF",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },

    }
  },
  computed: {
    switchStyle() {
      return {
        backgroundColor: this.isExternal ? "#64BFE5" : "#e1e1e1",
        color: "#fff",
      };
    }
  },
  created() {
    this.getItemData()
  },
  methods: {
    showItemDetailModal() {
      this.showItemDetail = true;
    },
    getItemData() {
      if(!this.itemId) return
      this.$spinner.fingerprint();
      this.$store
        .dispatch("items/fetchSelectData", this.itemId)
        .then((response) => {
          this.setItemFormData(response.data)
          this.$spinner.close();
        })
        .catch((error) => {
          console.log(error)
          this.$spinner.close();
          this.$swal({
            icon: "error",
            title: "Item Not Found",
          });
        });
    },
    setItemFormData(data){
      if(!data) return
      Object.entries(this.itemData).forEach(([key, value])=> {
        if(!["item_parts"].includes(key)){
          this.itemData[key] = data[key]
        }
        if(key === "item_parts" && data.item_parts.length > 0){
          this.itemData.item_parts = data.item_parts
          this.searchItem([...data.item_parts.map(v => v.item_part_id), this.externalId].join(","))
        }
        this.searchPackGroups(data.pack_group?.id)
      })
    },
    searchPackGroups(value) {
      if(!value || value.length < 3) return
      this.$store
        .dispatch("packgroup/fetchItems", {
          search: value,
          limit: 15,
        })
        .then((response) => {
          this.packGroups = response.data.results;
        });
    },
    searchItem(value, force=false) {
      if(!value || (!force && value.length < 3)) return
      this.$store
        .dispatch("items/fetchSearchItems", {
          search: value,
          limit: 15,
        })
        .then((response) => {
          this.items = response.data;
        });
    }
  },
}
</script>

<style scoped>

</style>
