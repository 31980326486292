<template>
  <div class="flex flex-col gap-6 w-full p-2 pt-5">
      <template v-if="itemData.type === 'alias'">
        <soludio-input v-for="(item, key) in itemData.item_parts" type="search" v-model="item.item_part_id" :options="items"
                       option-value="id" disabled
                       :key="key"
                       option-label="sku" label="Original Item"></soludio-input>
      </template>
      <template v-if="itemData.type === 'assembly'">
        <div v-for="(item, key) in itemData.item_parts" class="flex gap-2 items-center">
          <soludio-input class="w-4/5" type="search" v-model="item.item_part_id" :options="items"
                         option-value="id" disabled
                         :key="key"
                         option-label="sku" :label="`Bill Of Material ${key +1}`"></soludio-input>
          <soludio-input class="w-1/5" disabled v-model.number="item.quantity" type="number"
                         label="QTY"></soludio-input>
        </div>
      </template>

        <soludio-input  disabled v-model="itemData.sku" label="Sku Number" ></soludio-input>
        <soludio-input disabled v-model="itemData.upc"  label="UPC (optional)"></soludio-input>
        <soludio-input disabled v-model="itemData.description"  label="Description (optional)"></soludio-input>
        <soludio-input disabled v-model="itemData.external_sku_ref_list"  type="text-area" label="External SKU Ref List (optional)"></soludio-input>
        <soludio-input v-if="itemData.type === 'assembly'" disabled v-model="itemData.assembly_packaging_type" type="select" :options="assemblyPackagingTypes" option-value="value" option-label="label" label="Assembly Item Shipment Details"></soludio-input>

        <template v-if="itemData.type !== 'assembly' || (itemData.type === 'assembly' && itemData.assembly_packaging_type === 'consolidated')">
            <soludio-input  disabled v-model="itemData.length"  type="number" label="Length(In.)"></soludio-input>
            <soludio-input disabled v-model="itemData.width"  type="number" label="Width(In.)"></soludio-input>
            <soludio-input disabled v-model="itemData.height"  type="number" label="Height(In.)"></soludio-input>
            <soludio-input disabled v-model="itemData.weight"  type="number" label="Weight(Lb.)"></soludio-input>
            <soludio-input disabled v-model="itemData.pack_group.id"
                           @input="searchPackGroups"
                           option-value="id"
                           option-label="name" :options="packGroups" type="search"  label="Packgroup"></soludio-input>
            <soludio-input disabled v-model="itemData.print_folder"  label="Print Folder"></soludio-input>
            <soludio-input disabled v-model="itemData.shipment_type" type="select" :options="shipmentTypes" option-value="value" option-label="label"  label="Shipment Type" ></soludio-input>
            <soludio-input disabled v-model="itemData.sku_on_label" disabled type="select"  :options="skuOnLabels" option-value="value" option-label="label"  label="SKU On Label" ></soludio-input>
            <soludio-input disabled v-model="itemData.shipping_label_sort_order_prefix"  label="Shipping Label Sort Order Prefix"></soludio-input>
        </template>
  </div>
</template>

<script>
import Data from "@/views/items/item/forms/data";
import Methods from "@/views/items/item/forms/methods";

export default {
  name: "ReadOnlyItem",
  mixins: [Data, Methods],
  props: {
    close: {
      type: Function,
      default: ()=> {}
    },
    currentItemData: Object,
    itemId:[String, Number],
  },
  data(){
    return {
      itemData: {
        id: null,
        type: 'single',
        sku: null,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        assembly_packaging_type: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        pack_group: {
          id: null,
          name: null
        },
        item_parts: [
          {
            item_part_id: null,
            quantity: 1
          }
        ],
        sku_on_label: "SELF",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },

    }
  },
  created() {
    this.searchPackGroups(this.currentItemData?.pack_group?.id)
    this.searchItem(this.currentItemData.item_parts.map(v => v.item_part_id).join(","), true)
    this.setItemFormData(this.currentItemData)
  },
  methods: {
    setItemFormData(data){
      if(!data) return
      Object.entries(this.itemData).forEach(([key, value])=> {
        if(key !== "sku_on_label"){
          this.itemData[key] = data[key]
        }
      })
    }
  },
  watch: {
    currentItemData: {
      handler(newValue){
        if(!newValue) return
        this.searchPackGroups(this.currentItemData?.pack_group?.id)
        this.searchItem(this.currentItemData.item_parts.map(v => v.item_part_id).join(","), true)
        this.setItemFormData(this.currentItemData)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
