import { render, staticRenderFns } from "./right-side.vue?vue&type=template&id=2672b25e&scoped=true&"
import script from "./right-side.vue?vue&type=script&lang=js&"
export * from "./right-side.vue?vue&type=script&lang=js&"
import style0 from "./right-side.vue?vue&type=style&index=0&id=2672b25e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2672b25e",
  null
  
)

export default component.exports