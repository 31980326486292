import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {
  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/", {
          warehouse_name: item.selected,
          name: item.name,
          store: item.store,
          company: item.company,
          phone: item.phone,
          address1: item.address1,
          address2: item.address2,
          city: item.city,
          state: item.state,
          zipcode: item.zipcode,
          country: item.country,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  marketplaceItemInventoryUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/marketplace_item_inventory_bulk_upload_json/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  marketplaceExternalInventoryUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/external_inventory_update/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  marketplaceCostsUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/marketplace_cost_bulk_upload_json/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMarketplaceCostsUploadResponse(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/get_marketplace_cost_bulk_upload_json_response/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  marketplacePriceInfoUpload(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/marketplace_price_info_bulk_upload_json/", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLastUploadedInventoryFileDate(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/marketplace_last_uploaded_files_date/" + queryset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePricesInMarketplace(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/update_prices_in_marketplace/" + queryset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateLogisticsInformationInMarketplace(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/update_logistics_in_marketplace/" + queryset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateInventoryInMarketplace(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/update_inventory_in_marketplace/" + queryset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAwesungWarehouseCodes(ignore, payload){
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/fetch_awesung_warehouse_codes/", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  syncInvoiceFileSettings(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/marketplace/marketplaces/sync_invoice_file_settings/", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchMarketplace({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/" + queryset)
        .then(response => {
          commit("SET_MARKETPLACES", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchMarketplaceQuantity(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/quantity-settings/" + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchMarketplaceIntegration(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/integration-settings/" + id + "/")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchMarketplaceStoreApis({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/store-apis-settings/" + id + "/")
        .then(response => {
          commit('SET_STORE_DATA', response.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchMarketplaceInvoice(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/invoice-file-settings/" + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchMarketplaceCompany(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/company-settings/" + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchMarketplaceIntegrations(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/integrations-settings/" + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateMarketplaceIntegrations(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/integrations-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  createMarketplaceIntegrationSettings(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`marketplace/integrations-settings/`, item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  deleteMarketplaceIntegrationSettings(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/marketplace/integrations-settings/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  checkMarketplaceItemInfoLogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/download_marketplace_item_info_logs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getShopifyRedirectUri(ignore, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();
      axios
        .get(`/marketplace/marketplaces/get_shopify_redirect_uri/${queryset}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  recentlyUploadedInventoryReferences(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/recently_uploaded_inventory_references/" + queryset, { responseType: 'arraybuffer' })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  recentlyUploadedCostsReferences(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/recently_uploaded_costs_references/" + queryset, { responseType: 'arraybuffer' })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updatedMarketplacePrices(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/updated_marketplace_prices/" + queryset, { responseType: 'arraybuffer' })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  recentlySentMarketplacePayload(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/download_last_inventory_uploaded_file/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearMarketplaceItemInfoLogs() {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/clear_marketplace_item_info_logs/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchMarketplaceCarrierSettingsServices(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/carrier-settings-services/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSelectData({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/" + id + "/")
        .then(response => {
          //return array
          let defaultCarrier = response.data.carrier_settings ? response.data.carrier_settings.filter(v => v).map(v => v.carrier)
            : [];
          let invoiceFileSetting = response.data.invoice_file_setting;

          Object.assign(invoiceFileSetting, {
            defaultCarrier: defaultCarrier && defaultCarrier.length ? defaultCarrier[0] : ""
          });

          commit("SET_SELECT_DATA", response.data);
          commit("SET_INVOICE_DATA", invoiceFileSetting);
          commit("SET_STORE_DATA", response.data.store_apis_setting);
          commit("SET_COMPANY_DATA", response.data.company_setting);
          commit("SET_CARRIER_DATA", response.data.carrier_settings);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateCarrier(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/marketplaces/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateInvoice(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/invoice-file-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateCompany(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/company-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  createCarrierSetting(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/marketplace/carrier-settings/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchCarrierSettings(ignore, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get(`/marketplace/carrier-settings/${queryset}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  deleteCarrierSetting(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/marketplace/carrier-settings/${id}/`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateCarrierSetting(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/carrier-settings/${payload.id}/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  partialUpdateCarrierSetting(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/marketplace/carrier-settings/${payload.id}/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  partialUpdateQuantitySetting(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/marketplace/quantity-settings/${payload.id}/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateQuantity(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/quantity-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateIntegration(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/integration-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateStore(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/store-apis-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  partialUpdateStore(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/marketplace/store-apis-settings/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/marketplace/marketplaces/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchStores() {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/list_stores/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchItemCosts() {
    return new Promise((resolve, reject) => {
      axios
        .get("/marketplace/marketplaces/item_costs_list/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateMarketplace(ignore, item) {
    let id = item.id
    return new Promise((resolve, reject) => {
      axios
        .put(`/marketplace/marketplaces/` + id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carrierDataUpdate({ commit }, services) {
    commit("SET_CARRIER_DATA2", services);
  },


  downloadQtyFile({ commit }, data) {
    var queryset = "";

    queryset += "?warehouse=" + data.quantity_setting.warehouse;
    queryset += "&threshold=" + data.quantity_setting.threshold;

    return new Promise((resolve, reject) => {
      axios
        .get(
          "/item_inventory/inventories/get_marketplace_inventory/" + queryset
        )
        .then(response => {
          commit("SET_QTY_FILE", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchWarehouse({ commit }, options) {
    return new Promise((resolve, reject) => {
      let helpers = new requestsHelper(options);
      let queryset = helpers.createQueryset();

      axios
        .get("/warehouse/warehouses/" + queryset)
        .then(response => {
          commit("SET_WAREHOUSES", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchSingleWarehouse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/warehouse/warehouses/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addScheduler(ignore, event){
    return new Promise((resolve, reject) => {
      axios
        .post("/schedule/schedules/", {
          action: event.action,
          monday: event.monday,
          tuesday: event.tuesday,
          wednesday: event.wednesday,
          thursday: event.thursday,
          friday: event.friday,
          saturday: event.saturday,
          sunday: event.sunday,
          time: event.time,
          marketplace: event.marketplace,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchSchedules({ commit }, options){
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/schedule/schedules/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeSchedule(ignore, id){
    return new Promise((resolve, reject) => {
      axios
        .delete(`/schedule/schedules/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchSingleSchedule({commit}, id){
    return new Promise((resolve, reject) => {
      axios
        .get("/schedule/schedules/" + id + "/")
        .then(response => {
          commit("SET_EDIT_ACTION", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSchedule(ignore, event){
    return new Promise((resolve, reject) => {
      axios
        .put(`/schedule/schedules/` + event.id + "/", {
          action: event.action,
          monday: event.monday,
          tuesday: event.tuesday,
          wednesday: event.wednesday,
          thursday: event.thursday,
          friday: event.friday,
          saturday: event.saturday,
          sunday: event.sunday,
          time: event.time,
          marketplace: event.marketplace
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchActionChoices(){
    return new Promise((resolve, reject) => {
      axios
        .get("/schedule/schedule_set/list_action_choices/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
