<template>
  <div class="relative grid grid-rows-2 gap-4">
    <div class="font-semibold">
      {{ label }}
    </div>
    <div class="flex flex-row gap-4">
      <div
        v-for="obj in fixedOptions"
        :key="obj.val"
        class="flex flex-row gap-1 pl-1 mb-1"
      >
        <div>
          <input
            v-bind="filterAttrs"
            type="radio"
            :data-cy="dataCy"
            :value="obj.value"
            name="name"
            @input="sendEmit(obj.value)"
            :id="obj.value"
            :checked="value == obj.value"
          />
        </div>
        <div>
          <label for="obj.value" class="flex items-center gap-1 pl-1 mb-1">
            {{ obj.label }}
            <soludio-tooltip
              v-if="tooltip"
              :type="tooltipType"
              :tooltip="tooltip"
            ></soludio-tooltip>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SoludioTooltip from "@/components/soludio-input/partials/SoludioTooltip.vue";
export default {
  name: "soludio-radio-input",
  components: {
    SoludioTooltip,
  },
  props: {
    id: String,
    label: String,
    tooltip: String,
    tooltipType: String,
    optionLabel: String,
    optionValue: String,
    placeholder: String,
    dataCy: String,
    value: {},
    options: {
      type: [Array, String],
    },
    size: {
      type: String,
      default: "base",
      options: ["large", "base", "small"],
    },
  },
  data() {
    return {};
  },
  computed: {
    fixedOptions() {
      if (!Array.isArray(this.options)) {
        return this.options.split(",").map((v) => v.trim());
      }
      return this.options;
    },
    filterAttrs() {
      // eslint-disable-next-line no-unused-vars
      const { size, ...attrs } = this.$attrs;
      return attrs;
    },
  },
  methods: {
    sendEmit(val) {
      this.$emit("input", val);
    },
    setLabel(data) {
      if (!this.optionLabel) return data;
      let optionLabel = this.optionLabel || "label";
      return data[optionLabel];
    },
    setValue(data) {
      if (!this.optionValue) return data;
      let optionValue = this.optionValue || "value";
      return data[optionValue];
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../css/basic.scss";
.custom-input {
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
  
  
  
  
  