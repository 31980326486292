import Amazon from "@/assets/images/stores/amazon.png";
import Wayfair from "@/assets/images/stores/wayfair.png";
import Walmart from "@/assets/images/stores/walmart.png";
import Houzz from "@/assets/images/stores/houzz.png";
import Ebay from "@/assets/images/stores/ebay.png";
import Etsy from "@/assets/images/stores/etsy.png";
import Kohls from "@/assets/images/stores/kohls.png";
import Target from "@/assets/images/stores/target.png";
import Macys from "@/assets/images/stores/macys.png";
import Lowes from "@/assets/images/stores/lowes.png";
import Thehomedepot from "@/assets/images/stores/thehomedepot.png";
import Overstock from "@/assets/images/stores/overstock.png";
import Shopify from "@/assets/images/stores/shopify.png";
import MobilityECommerce from "@/assets/images/stores/mobility.png";
import Fedex from "@/assets/images/carriers/fedex.png";
import UPS from "@/assets/images/carriers/ups.png";
import Purolator from "@/assets/images/carriers/purolator.png";
import Stamps from "@/assets/images/carriers/stamps.png";
import Package from "@/assets/images/placeholders/package-placeholder.png";
import Warehouse from "@/assets/images/placeholders/warehouse-placeholder.png";
import Printer from "@/assets/images/placeholders/printer-placeholder.png";
import Schedule from "@/assets/images/placeholders/schedule-placeholder.png";
import User from "@/assets/images/placeholders/user-placeholder.png";

export default {
  amazon: {
    imageSrc: Amazon,
  },
  "amazon-vendor-direct-fulfillment": {
    imageSrc: Amazon,
  },
  wayfair: {
    imageSrc: Wayfair,
  },
  shopify: {
    imageSrc: Shopify,
  },
  walmart: {
    imageSrc: Walmart,
  },
  "walmart-seller": {
    imageSrc: Walmart,
  },
  "walmartca": {
    imageSrc: Walmart,
  },
  "walmart-drop-ship-vendor": {
    imageSrc: Walmart,
  },
  "mobility-ecommerce": {
    imageSrc: MobilityECommerce,
  },
  cgwayfair: {
    imageSrc: Wayfair,
  },
  houzz: {
    imageSrc: Houzz,
  },
  ebay: {
    imageSrc: Ebay,
  },
  etsy: {
    imageSrc: Etsy,
  },
  kohls: {
    imageSrc: Kohls,
  },
  dsco: {
    imageSrc: Kohls,
  },
  macys: {
    imageSrc: Macys,
  },
  target: {
    imageSrc: Target,
  },
  lowes: {
    imageSrc: Lowes,
  },
  thehomedepot: {
    imageSrc: Thehomedepot,
  },
  overstock: {
    imageSrc: Overstock,
  },
  fedex: {
    imageSrc: Fedex,
  },
  ups: {
    imageSrc: UPS,
  },
  purolator: {
    imageSrc: Purolator,
  },
  stamps: {
    imageSrc: Stamps,
  },
  package: {
    imageSrc: Package,
  },
  warehouse: {
    imageSrc: Warehouse,
  },
  printer: {
    imageSrc: Printer,
  },
  schedule: {
    imageSrc: Schedule,
  },
  user: {
    imageSrc: User,
  }
};
