import { render, staticRenderFns } from "./ReadOnlyItem.vue?vue&type=template&id=52ea0bfe&scoped=true&"
import script from "./ReadOnlyItem.vue?vue&type=script&lang=js&"
export * from "./ReadOnlyItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ea0bfe",
  null
  
)

export default component.exports