import requestsHelper from "@/services/helpers/requestsHelper";
import axios from "@/axios";
import store from "@/store/store";

export default {
  importFetchLogs({ commit }, data) {
    commit('SET_IMPORT_FETCH_LOGS', data)
  },
  uploadOrderWizard({ commit }, data) {
    commit("SET_UPLOAD_ORDER_WIZARD_STATUS", data)
  },
  lastMessage({ commit }, data) {
    commit('SET_LAST_MESSAGE', data)
  },
  systemNotification({ commit },message){
    store.dispatch('websocket/lastMessage',message).then(()=>{})
    switch (message.name){
      case "frontend_import_fetch_logs":
        store.dispatch('websocket/importFetchLogs',message).then(()=>{})
        break;
      case "frontend_upload_order_wizard":
        store.dispatch('websocket/uploadOrderWizard',message).then(()=>{})
        break;
    }
  },
  pushNotification({ commit },message){
    commit("SET_LAST_NOTIFICATION", message);
  },
  fetchNotifications({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();

    return new Promise((resolve, reject) => {
      axios
        .get("/notification/notifications/" + queryset)
        .then(response => {
          commit("SET_NOTIFICATIONS", response.data.results);
          commit("SET_UNREAD_NOTIFICATION_COUNT", response.data?.unread_notification_count);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  markAllNotificationsAsRead({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/notification/notifications/mark_all_notifications_as_read/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  markNotificationAsRead({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/notification/notifications/mark_notification_as_read/" + queryset)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
