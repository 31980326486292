<template>
  <div class="flex flex-col gap-6 p-5">
    <soludio-input class="w-2/3 mt-5" v-model="itemData.sku" label="Sku Number" data-cy="alias-sku" placeholder="Please Enter Sku Number"></soludio-input>
    <soludio-input v-model="itemData.product_class" type="select" :options="productTypes" option-value="value" option-label="label" class="w-2/3" data-cy="alias-product-type" label="Product Type" placeholder="Please Enter Product Type"></soludio-input>
    <div v-for="(item, key) in itemData.item_parts" class="w-2/3 flex gap-3">
      <soludio-input class="w-4/5" type="search" v-model="item.item_part_id" @input="searchItem" :options="items"
                     option-value="id"
                     :key="key"
                     option-label="sku" label="Original Item" data-cy="alias-original-item" placeholder="Please Enter Original Item"></soludio-input>
      <soludio-input v-model="item.quantity" disabled class="w-1/5" tooltip-type="question" tooltip="You cannot change original item quantity, original item's quantity always must be 1" type="number"
                     data-cy="alias-quantity" label="Quantity"></soludio-input>
    </div><div class="flex flex-col gap-5">
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 flex font-semibold text-gray-700 items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMyA2LjA2NnYxMi4wNjVsLTExLjAwMSA1Ljg2OS0xMS01Ljg2OXYtMTIuMTMxbDExLTYgMTEuMDAxIDYuMDY2em0tMjEuMDAxIDExLjQ2NWw5LjUgNS4wNjl2LTEwLjU3bC05LjUtNC45NDZ2MTAuNDQ3em0yMC4wMDEtMTAuMzg4bC05LjUwMSA0Ljg4OXYxMC41NjhsOS41MDEtNS4wNjl2LTEwLjM4OHptLTUuNTIgMS43MTZsLTkuNTM0LTQuOTY0LTQuMzQ5IDIuMzczIDkuNDA0IDQuODk2IDQuNDc5LTIuMzA1em0tOC40NzYtNS41NDFsOS41NjUgNC45OCAzLjgzMi0xLjk3Mi05LjQwNS01LjE4NS0zLjk5MiAyLjE3N3oiLz48L3N2Zz4="/>
          Item Information</h3>
        <soludio-input v-model="itemData.upc" class="w-2/3" data-cy="alias-upc" label="UPC (optional)" placeholder="Please Enter UPC"></soludio-input>
        <soludio-input v-model="itemData.description" class="w-2/3" data-cy="alias-description" label="Description (optional)" placeholder="Please Enter Description" disabled tooltip-type="question" tooltip="You cannot change description, because this item type is alias."></soludio-input>
        <soludio-input v-model="itemData.external_sku_ref_list" class="w-2/3" type="text-area" tooltip="Enter external SKU typos. Use comma to separate values."
                        data-cy="alias-external-sku" label="External SKU Ref List (optional)" placeholder="Please Enter Description" ></soludio-input>
        <soludio-input v-model="itemData.sellable" class="w-2/3" data-cy="alias-sellable" @input="setSellable" label="Is This Item Sellable ?" :options="[{ value:true, label:'True' }, {value:false, label:'False' }]" option-value="value" option-label="label" type="radio" disabled></soludio-input>
      </div>
      <div class="flex flex-col gap-4">
        <h3 class="text-xl font-light my-5 font-semibold text-gray-700 flex items-center gap-2">
          <img height="24"
               src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYyIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOCA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0zIDE5YzAgMS4zMDItLjgzOSAyLjQwMS0yIDIuODE2di0yLjgxNmgtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2M2gtMnYtM2gtMXYzaC0ydi0zaC0xdjNoLTJ2LTNoLTF2Mi44MTVjLTEuMTYxLS40MTQtMi0xLjUxMy0yLTIuODE1di0xNGMwLTEuMzAyLjgzOS0yLjQwMSAyLTIuODE2djIuODE2aDF2LTNoMnY2aDF2LTZoMnYzaDF2LTNoMnYzaDF2LTNoMnY2aDF2LTZoMnYzaDF2LTIuODE2YzEuMTYxLjQxNSAyIDEuNTE0IDIgMi44MTZ2MTR6bS0xNC4xNDEtOWgxLjE0MXY2aC0xLjM1NHYtMy44ODloLTEuNDg4di0xYy44OTMtLjA0MSAxLjcwMS0uMjAxIDEuNzAxLTEuMTExem04LjMxNSA0LjY2N2gxLjgyNnYxLjMwNmgtMy45MjJ2LS45NThjMS41MjktMS40OTEgMi4zNzktMi4yNDQgMi4zODktMy4wMTggMC0uNDYyLS4yNjgtLjcxNy0uNzU0LS43MTctLjQ1MyAwLS44MTYuMjQ1LTEuMTY4LjUyNGwtLjQyNC0xLjE0OGMuNTQ1LS40NTIgMS4xOTEtLjY1NiAxLjgzMy0uNjU2IDEuMTc5IDAgMS45NDEuNzQgMS45NDEgMS44ODUuMDAxIDEuMTU1LS44OTkgMi4wNDMtMS43MjEgMi43ODJ6Ii8+PC9zdmc+"/>
          Shipment Details</h3>
          <div class="flex gap-2 w-2/3 bg-orange-600 p-6" v-if="changeFormValidation">
            <h5>This original item is an assembly item and it has multiple packages.</h5>
          </div>
        <div class="flex gap-2 w-2/3">
          <soludio-input v-model="itemData.length" disabled class="w-1/3" data-cy="alias-length" tooltip-type="question" tooltip="You cannot change length, because this item type is alias." type="number"
                         label="Length(In.)" placeholder="Please Enter Length"></soludio-input>
          <soludio-input v-model="itemData.width" disabled class="w-1/3" data-cy="alias-width" tooltip-type="question" tooltip="You cannot change width, because this item type is alias." type="number" label="Width(In.)"
                         placeholder="Please Enter Width"></soludio-input>
          <soludio-input v-model="itemData.height" disabled class="w-1/3" data-cy="alias-height" tooltip-type="question" tooltip="You cannot change height, because this item type is alias." type="number" label="Height(In.)"
                         placeholder="Please Enter Height"></soludio-input>
        </div>
        <soludio-input v-model="itemData.weight" disabled class="w-2/3" data-cy="alias-weight" tooltip-type="question" tooltip="You cannot change weight, because this item type is alias." type="number" label="Weight(Lb.)"
                       placeholder="Please Enter Weight"></soludio-input>
        <soludio-input v-model="itemData.pack_group.id"
                       @input="searchPackGroups"
                       disabled
                       option-value="id"
                       tooltip-type="question" tooltip="You cannot change pack group, because this item type is alias."
                       option-label="name" :options="packGroups" type="search" class="w-2/3"  data-cy="alias-packgroup" label="Packgroup"
                       placeholder="Please Enter Packgroup"></soludio-input>
        <soludio-input v-model="itemData.print_folder" disabled class="w-2/3" data-cy="alias-print-folder"  label="Print Folder" tooltip-type="question" tooltip="You cannot change print folder, because this item type is alias."
                       placeholder="Please Enter Print Folder"></soludio-input>
        <soludio-input v-model="itemData.shipment_type" type="select" :options="shipmentTypes" disabled tooltip-type="question" data-cy="alias-shipment-type" tooltip="You cannot change shipment type, because this item type is alias." option-value="value" option-label="label" class="w-2/3" label="Shipment Type" placeholder="Please Enter Shipment Type"></soludio-input>
        <soludio-input v-model="itemData.shipping_label_sort_order_prefix" class="w-2/3" data-cy="alias-sort-order" label="Shipping Label Sort Order Prefix"
                       tooltip-type="question" disabled tooltip="You cannot change shipping label sort order prefix, because this item type is alias."
                       placeholder="Please Enter Shipping Label Sort Order Prefix"></soludio-input>
      </div>
    </div>
    <div class="flex">
      <pg-button @click="save" :disabled="!formValidation" color="green" data-cy="alias-save-button" class="w-32">Save</pg-button>
    </div>
  </div>
</template>

<script>
import Data from "@/views/items/item/forms/data";
import Methods from "@/views/items/item/forms/methods";

export default {
  name: "AddAlias",
  mixins: [Data, Methods],
  props: {
    close: {
      type: Function,
      default: ()=> {}
    },
    createItem: String,
    setItemParts: {
      type: Function,
      default: ()=> {}
    },
  },
  data(){
    return {
      productTypes: [
        {label: "Goods", value: "goods"},
        {label: "Service", value: "services"}
      ],
      itemData: {
        type: 'alias',
        product_class: 'goods',
        sellable: true,
        sku: this.createItem,
        upc: null,
        description: null,
        external_sku_ref_list: "",
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        assembly_packaging_type: null,
        pack_group: {
          id: null
        },
        item_parts: [
          {
            item_part_id: null,
            quantity: 1
          }
        ],
        sku_on_label: "BASE",
        print_folder: null,
        shipping_label_sort_order_prefix: null,
        shipment_type: null
      },
      changeFormValidation: false,
    }
  },
  computed: {
    itemPartsValidation(){
      if(this.itemData.item_parts.length !== 1) return false
      if(this.itemData.item_parts[0].quantity !== 1) return false
      return !!this.itemData.item_parts[0].item_part_id
    },
    itemDimensionValidation(){
      if(
        this.itemData.weight == 0 ||
        this.itemData.width == 0 || 
        this.itemData.length == 0 || 
        this.itemData.height == 0
       ){
        return false;
      }
      return true;
    },
    formValidation(){
      if (this.changeFormValidation){
        return !!this.itemPartsValidation &&
        !!this.itemData?.sku 
      }
      return !!this.itemPartsValidation &&
        !!this.itemData?.sku &&
        !!this.itemDimensionValidation
    }
  },

  methods: {
    setSellable(value){
      this.itemData.sellable = value;
    },
    setItemDataFromOriginalItem(originalItemData){
      console.log(originalItemData)
        let disabledKeys = ["length", "height", "width", "weight", "pack_group", "print_folder", "description",
          "shipment_type", "shipping_label_sort_order_prefix", "sellable"]
          Object.entries(originalItemData).forEach(([key,value])=> {
            if(disabledKeys.includes(key)){
              this.itemData[key] = value
            }
          })
          //this.searchPackGroups(this.itemData.pack_group.id)
    },
    save(){
      let {pack_group, ...data} = this.itemData
      data["pack_group_id"] = pack_group.id
      data["type"] = "alias"
      data["assembly_packaging_type"] = null

      this.$store
        .dispatch("items/addItem", data)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Item Created Successfully",
            text: "Successfully Created",
          }).then(()=> {
            this.close()
          })
        })
        .catch((error) => {
          if (error.status && error.status === 205) {
            error = "Item Already Exist";
          }
          if (error.response.status === 400){
            error = error.response.data['error']
          }
          this.$swal({
            icon: "error",
            title: "Failed to create product",
            text: error || "An error occured",
          });
        });
    }
  },
  watch: {
    "itemData.item_parts": {
      handler(newValue){
        this.setItemParts(newValue)
        if(!newValue || newValue.length !== 1) return
        let originalItem = this.items.find((item) => item.id === newValue[0]?.item_part_id)
        if(!originalItem) return
        this.changeFormValidation = originalItem.type == 'assembly' && originalItem.assembly_packaging_type == 'individual'
        this.setItemDataFromOriginalItem(originalItem)
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
