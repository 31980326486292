<template>
<figure @click="clickEvent">
  <img class="icon-style" :src="base64" v-bind="$attrs">
</figure>
</template>

<script>
import {icons} from "./icons"
export default {
  name: "index.vue",
  data(){
    return {
      icons: icons
    }
  },
  props:{
    name:String
  },
  computed:{
    base64(){
      return this.icons.find((icon)=> icon.name === this.name)?.base64
    }
  },
  methods:{
    clickEvent(event){
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
  figure{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-style{
    cursor: pointer;
  }
</style>
