import axios from "@/axios";
import requestsHelper from "@/services/helpers/requestsHelper";

export default {


  addItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/carrier/carrier_accounts/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchShippingAccounts({ commit }, options) {
    let helpers = new requestsHelper(options);
    let queryset = helpers.createQueryset();
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_accounts/" + queryset)
        .then(response => {
          commit("SET_CARRIER_ACCOUNTS", response.data.results);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  fetchSingleCarrierAccount({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_accounts/" + id + "/")
        .then(response => {
          commit("SET_EDIT_DATA", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAllContainersOfSingleCarrierAccount(ignore, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package/containers/fetch_containers_of_carrier_account/", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getCarrierFormLabels(ignore, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/carrier/carrier_accounts/get_carrier_accounts_label/`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  getStampsStatus(ignore) {
    return new Promise((resolve, reject) => {
      axios
        .get("/carrier/carrier_accounts/get_stamps_account/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAwesungAccountBalance(ignore, obj){
    return new Promise((resolve, reject) => {
      axios
        .post("/carrier/carrier_accounts/get_awesung_account_balance/", obj)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addFundsToAccountBalance(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/carrier/carrier_accounts/add_funds_to_account_balance/`, item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  updateItem(ignore, item) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/carrier/carrier_accounts/` + item.id + "/", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  removeItem(ignore, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/carrier/carrier_accounts/` + id + "/")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
